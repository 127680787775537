import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex, Text } from '@chakra-ui/react';

import { FormattedStatus, Voucher } from '../../../interfaces/voucher';
import { priceFormatter } from '../../../utils/priceFormatter';
import textContent from '../../../utils/textContent.json';

export interface ReservationHistoryCardProps {
  voucher: Voucher;
}

const ReservationHistoryCard = ({ voucher }: ReservationHistoryCardProps) => {
  const navigate = useNavigate();
  const formattedDate = DateTime.fromISO(voucher.date).toUTC().toFormat('dd/MM/yyyy');
  const totalPrice = useMemo(() => {
    const total = voucher.products.reduce((acc, product) => {
      return acc + product.finalPrice;
    }, 0);

    return priceFormatter.format(total);
  }, [voucher.products]);

  return (
    <Flex
      height="147px"
      maxWidth={{ base: '375px', md: '721px' }}
      width="100%"
      padding="16px 32px 16px 24px"
      margin={{ xl: '0px 112px' }}
      borderBottom="1px solid #E2E8F0"
      backgroundColor={{ base: 'grey50', md: 'background' }}
      flexDirection="column"
      data-testid="reservation-card-container"
    >
      <Flex flexDirection="column" alignItems="flex-start" marginBottom="16px" height="58px">
        <Flex>
          <Text
            fontSize="14px"
            fontWeight="500"
            color="grey900"
            marginRight="3px"
            data-testid="reservation-card-date-label"
          >
            {textContent.reservationHistoryText.date}:
          </Text>
          <Text
            fontSize="14px"
            fontWeight="400"
            color="grey900"
            data-testid="reservation-card-date"
          >
            {formattedDate}
          </Text>
        </Flex>
        <Flex>
          <Text
            fontSize="14px"
            fontWeight="500"
            color="grey900"
            marginRight="3px"
            data-testid="reservation-card-reservation-code-label"
          >
            {textContent.reservationHistoryText.reservationCode}:
          </Text>
          <Text
            fontSize="14px"
            fontWeight="400"
            color="grey900"
            data-testid="reservation-card-reservation-code"
          >
            {voucher.IDTablaWS}
          </Text>
        </Flex>
        <Flex>
          <Text
            fontSize="14px"
            fontWeight="500"
            color="grey900"
            marginRight="3px"
            data-testid="reservation-card-state-label"
          >
            {textContent.reservationHistoryText.state}:
          </Text>
          <Text
            fontSize="14px"
            fontWeight="400"
            color="grey900"
            data-testid="reservation-card-state"
          >
            {voucher.status}
          </Text>
        </Flex>
      </Flex>
      <Flex flexDirection="column" alignItems="flex-start">
        <Flex>
          <Text
            fontSize="18px"
            fontWeight="700"
            color="grey900"
            marginRight="3px"
            data-testid="reservation-card-total-label"
          >
            {textContent.total}:
          </Text>
          <Text fontSize="18px" fontWeight="700" data-testid="reservation-card-total">
            {totalPrice}
          </Text>
        </Flex>
      </Flex>
      <Flex flexDirection="column" alignItems="flex-end" height="21px">
        <Text
          fontSize="12px"
          fontWeight="500"
          textDecoration="underline"
          color="purple"
          cursor="pointer"
          data-testid="reservation-card-repeat-label"
          onClick={() => navigate(`/reservation-history/${voucher.IDTablaWS}`)}
        >
          {voucher.status === FormattedStatus.Anulada
            ? textContent.reservationHistoryText.seeDetail
            : textContent.reservationHistoryText.repeat}
        </Text>
      </Flex>
    </Flex>
  );
};

export default ReservationHistoryCard;
