import useSWRImmutable from 'swr/immutable';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Flex } from '@chakra-ui/react';

import { ModalType } from '../../../enum/ModalType';
import { useBreakpoint } from '../../../hooks/useBreakpoint';
import { FormattedStatus, VoucherProductWithStock } from '../../../interfaces/voucher';
import { getVoucherById } from '../../../services/voucher';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getUserPriceList } from '../../../store/modules/auth';
import { showModal } from '../../../store/modules/ui';
import textContent from '../../../utils/textContent.json';
import {
  decrementQuantity,
  getTotalPriceVouchersCart,
  incrementQuantity,
  removeItem,
  replaceQuantity,
} from '../../../utils/vouchers';
import GoBack from '../../shared/GoBack/GoBack';
import CartButtonCounter from '../../shared/ReservationProductCard/CartButtonCounter';
import ReservationProductCard from '../../shared/ReservationProductCard/ReservationProductCard';
import TotalPrice from '../../shared/TotalPrice/TotalPrice';
import ReservationHistoryCardSkeleton from '../ReservationHistory/ReservationHistoryCardSkeleton';

const RepeatReservation = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { voucherId } = useParams();
  const { breakPoint } = useBreakpoint();
  const [products, setProducts] = useState<VoucherProductWithStock[]>([]);
  const [error, setError] = useState<{ [key: string]: string }>({});
  const userPriceList = useAppSelector(getUserPriceList);

  const printRef = useRef<HTMLDivElement>(null);

  const totalPrice = useMemo(() => {
    return getTotalPriceVouchersCart(products);
  }, [products]);

  const { isLoading, data } = useSWRImmutable({ voucherId, userPriceList }, getVoucherById);

  useEffect(() => {
    if (data?.voucher.products) {
      setProducts(data.voucher.products);
    }
  }, [data]);

  const isCancelled = data?.voucher.status === FormattedStatus.Anulada;

  const isConfirmationDisabled = useMemo(() => {
    return products.some(
      (product) => product.totalStock < product.quantity || product.quantity === 0,
    );
  }, [products]);

  if (isLoading) {
    return <ReservationHistoryCardSkeleton />;
  }

  if (!isLoading && !products.length && !data) {
    navigate('/reservation-history');
  }

  const handleSubmit = async () => {
    dispatch(
      showModal({
        type: ModalType.CONFIRM_RESERVATION,
        data: {
          products,
        },
      }),
    );
  };

  return (
    <Flex
      justifyContent={{ base: 'flex-start' }}
      flexDirection="column"
      backgroundColor="grey50"
      data-testid="repeat-reservation-container"
      height={{ base: '100vh', md: '100%' }}
      width={{ md: '100%' }}
    >
      <GoBack
        title={textContent.reservationHistory}
        subTitle={voucherId && `Reserva nro ${voucherId}`}
      />
      <Box padding={{ md: '30px', xl: '32px 112px' }} backgroundColor="grey50">
        <Flex
          justifyContent="space-between"
          flexDirection={{ base: 'column', md: 'row' }}
          marginBottom={{ base: '170px', md: '0' }}
        >
          <Flex flexDirection="column" width={{ md: '65%', xl: '721px' }} ref={printRef}>
            {products?.map((item) => (
              <ReservationProductCard<VoucherProductWithStock>
                key={item.articleCode}
                inputName={`card-${item.articleCode}`}
                breakPoint={breakPoint}
                product={item}
                vouchersProducts={products}
                isCancelled={isCancelled}
                errors={error}
                setError={setError}
                setVoucher={setProducts}
                onClick={() => removeItem(products, item, setProducts)}
              >
                <CartButtonCounter<VoucherProductWithStock>
                  product={item}
                  breakPoint={breakPoint}
                  isCancelled={isCancelled}
                  onClickToReplaceQuantity={(quantity) =>
                    replaceQuantity(products, { ...item, quantity: quantity }, setProducts)
                  }
                  onClickToIncrementQuantity={() => incrementQuantity(products, item, setProducts)}
                  onClickToDecrementQuantity={() => decrementQuantity(products, item, setProducts)}
                  incrementDisabled={+item.totalStock <= item.quantity || false}
                />
              </ReservationProductCard>
            ))}
          </Flex>
          <TotalPrice
            disableConfirm={isConfirmationDisabled}
            total={totalPrice}
            rejectText={textContent.cancel}
            resolveText={textContent.reservationHistoryText.repeat}
            breakPoint={breakPoint}
            onClickRejectButton={() => navigate('/reservation-history')}
            onClickResolveButton={async () => {
              handleSubmit();
            }}
            dataTestId={'repeat-reservation-'}
            isCancelled={isCancelled}
          />
        </Flex>
      </Box>
    </Flex>
  );
};

export default RepeatReservation;
