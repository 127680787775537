import { get, patch, post, URL } from '../api';
import { GetAllProductsProps, GetByFiltersProps } from '../interfaces/products';
import {
  AddImageAndDescriptionProps,
  GetAdminProductsWithImageResponse,
  GetPaginatedProductsResponse,
  GetProductByIdResponse,
  GetProductsTableResponse,
} from '../store/modules/products/types';

export const getAllProducts = async ({ pagination, filters, searchValue }: GetAllProductsProps) => {
  const { pageIndex, pageSize } = pagination;
  const { brandValue, statusValue } = filters;

  const queries = `pageNumber=${
    pageIndex + 1
  }&pageSize=${pageSize}&status=${statusValue}&brand=${brandValue}&searchValue=${searchValue}`;

  const response = await get<GetProductsTableResponse>(`/admin${URL.GET_PRODUCTS}/all?${queries}`);

  return {
    products: response.data.data,
    brands: response.data.brands,
    totalPages: response.data.pagination.totalPages,
    total: response.data.pagination.total,
    isComplete: response.data.isComplete,
  };
};

export const getProductsByFilter = async ({
  deals,
  status,
  categoryCode,
  brand,
  currentPage,
  onlyStock = false,
}: GetByFiltersProps) => {
  const urlSearchParams = new URLSearchParams();
  urlSearchParams.set('pageSize', '18');
  urlSearchParams.set('pageNumber', currentPage.toString());
  if (deals) {
    urlSearchParams.set('deals', deals);
  }
  if (status) {
    urlSearchParams.set('status', status);
  }
  if (categoryCode) {
    urlSearchParams.set('categoryCode', categoryCode);
  }
  if (brand) {
    urlSearchParams.set('brand', brand);
  }
  if (onlyStock) {
    urlSearchParams.set('onlyStock', onlyStock.toString());
  }

  const response = await get<GetPaginatedProductsResponse>(
    `${URL.GET_PRODUCTS}?${urlSearchParams.toString()}`,
  );

  return {
    products: response.data.products,
    totalPages: response.data.pagination.totalPages,
  };
};

export const getAdminProductById = async (productId: string) => {
  const response = await get<GetAdminProductsWithImageResponse>(
    `/admin${URL.GET_PRODUCTS}/${productId}`,
  );

  return response.data;
};

export const updateImageToProduct = async ({
  productId,
  isActive,
  data,
  isNew,
}: AddImageAndDescriptionProps) => {
  if (isNew) return await post(`/admin${URL.GET_PRODUCTS}`, { ...data, productId, isActive });

  if (data.productImageUrl.startsWith('data:image')) {
    return await patch(`/admin${URL.GET_PRODUCTS}`, { ...data, productId, isActive });
  }

  return await patch(`/admin${URL.GET_PRODUCTS}`, {
    description: data.description,
    productId,
    isActive,
  });
};

export const getProductsByCategory = async ({
  categoryCode,
  currentPage,
  onlyStock = false,
}: {
  categoryCode?: string;
  currentPage: number;
  onlyStock?: boolean;
}) => {
  const response = await get<GetPaginatedProductsResponse>(
    `${
      URL.GET_PAGINATED_PRODUCTS_BY_CATEGORY
    }?categoryCode=${categoryCode}&pageNumber=${currentPage}&pageSize=${18}&onlyStock=${onlyStock}`,
  );

  return {
    products: response.data.products,
    totalPages: response.data.pagination.totalPages,
  };
};

export const getProductsByBrand = async ({
  brand,
  currentPage,
}: {
  brand?: string;
  currentPage: number;
}) => {
  const response = await get<GetPaginatedProductsResponse>(
    `${
      URL.GET_PAGINATED_PRODUCTS_BY_BRAND
    }?brand=${brand}&pageNumber=${currentPage}&pageSize=${18}`,
  );

  return {
    products: response.data.products,
    totalPages: response.data.pagination.totalPages,
  };
};

export const getProductsByDeals = async ({ currentPage }: { currentPage: number }) => {
  const response = await get<GetPaginatedProductsResponse>(
    `${URL.GET_PAGINATED_PRODUCTS_BY_DEALS}?pageNumber=${currentPage}&pageSize=${18}`,
  );

  return {
    products: response.data.products,
    totalPages: response.data.pagination.totalPages,
  };
};

export const getProductsPendingToReceive = async ({ currentPage }: { currentPage: number }) => {
  const response = await get<GetPaginatedProductsResponse>(
    `${URL.GET_PAGINATED_PRODUCTS_PENDING_TO_RECEIVE}?pageNumber=${currentPage}&pageSize=${18}`,
  );

  return {
    products: response.data.products,
    totalPages: response.data.pagination.totalPages,
  };
};

export const getProductsPendingToReceiveByCategory = async ({
  categoryCode,
  currentPage,
}: {
  categoryCode?: string;
  currentPage: number;
}) => {
  const response = await get<GetPaginatedProductsResponse>(
    `${
      URL.GET_PAGINATED_PRODUCTS_PENDING_TO_RECEIVE
    }/${categoryCode}?pageNumber=${currentPage}&pageSize=${18}`,
  );

  return {
    products: response.data.products,
    totalPages: response.data.pagination.totalPages,
  };
};

export const getProductsPendingToReceiveByCategoryAndBrand = async ({
  categoryCode,
  brand,
  currentPage,
}: {
  categoryCode?: string;
  brand?: string;
  currentPage: number;
}) => {
  const response = await get<GetPaginatedProductsResponse>(
    `${
      URL.GET_PAGINATED_PRODUCTS_PENDING_TO_RECEIVE
    }/${categoryCode}/${brand}?pageNumber=${currentPage}&pageSize=${18}`,
  );

  return {
    products: response.data.products,
    totalPages: response.data.pagination.totalPages,
  };
};

export const getProductsOnDealsByCategory = async ({
  categoryCode,
  currentPage,
}: {
  categoryCode?: string;
  currentPage: number;
}) => {
  const response = await get<GetPaginatedProductsResponse>(
    `${
      URL.GET_PAGINATED_PRODUCTS_BY_DEALS
    }/${categoryCode}?pageNumber=${currentPage}&pageSize=${18}`,
  );

  return {
    products: response.data.products,
    totalPages: response.data.pagination.totalPages,
  };
};

export const getProductsOnDealsByCategoryAndBrand = async ({
  categoryCode,
  brand,
  currentPage,
}: {
  categoryCode?: string;
  brand?: string;
  currentPage: number;
}) => {
  const response = await get<GetPaginatedProductsResponse>(
    `${
      URL.GET_PAGINATED_PRODUCTS_BY_DEALS
    }/${categoryCode}/${brand}?pageNumber=${currentPage}&pageSize=${18}`,
  );

  return {
    products: response.data.products,
    totalPages: response.data.pagination.totalPages,
  };
};

export const getProductsById = async ({ productId }: { productId: string }) => {
  const response = await get<GetProductByIdResponse>(`${URL.GET_PRODUCTS}/${productId}`);

  return response.data;
};

export const getHomeDealsProducts = async () => {
  const response = await get<GetPaginatedProductsResponse>(
    `${URL.GET_HOME_PAGINATED_PRODUCTS}/deals`,
  );

  return response.data.products;
};

export const getHomeOnTripProducts = async () => {
  const response = await get<GetPaginatedProductsResponse>(
    `${URL.GET_HOME_PAGINATED_PRODUCTS}/onTrip`,
  );

  return response.data.products;
};

export const getHomeOilsProducts = async () => {
  const response = await get<GetPaginatedProductsResponse>(
    `${URL.GET_HOME_PAGINATED_PRODUCTS}/oils`,
  );

  return response.data.products;
};

interface GetProductsFromSearchBarProps {
  currentPage: number;
  searchValue: string;
  pageSize: number;
  full: boolean;
}

export const getProductsFromSearchBar = async ({
  currentPage,
  pageSize,
  searchValue,
  full,
}: GetProductsFromSearchBarProps) => {
  const urlSearchParams = new URLSearchParams();
  urlSearchParams.set('pageNumber', currentPage.toString());
  urlSearchParams.set('pageSize', pageSize.toString());
  urlSearchParams.set('searchValue', searchValue.toLowerCase().trim());
  urlSearchParams.set('full', String(full));

  const response = await get<GetPaginatedProductsResponse>(
    `${URL.GET_FILTERED_PRODUCTS}?${urlSearchParams.toString()}`,
  );

  return {
    products: response.data.products,
    totalPages: response.data.pagination.totalPages,
  };
};
